import React from "react"
import styled from "styled-components"
import { Colors } from "../../colors/Colors"
import { AppointmentButton } from "../appointment/appointment-button"
import { motion } from "framer-motion/dist/framer-motion"
import Spacer from "../spacer"

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  grid-gap: 5rem;
  width: 100%;
  margin-bottom: 20px;

  h2 {
    font-family: "Articulat";
    color: ${Colors.gold};
    font-size: 2.2rem;
    font-weight: 800;
    margin-bottom: 16px;
  }

  p {
    font-family: "Articulat";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4;
    max-width: 45ch;
    margin-bottom: 16px;
  }

  @media (max-width: 800px) {
    grid-gap: 2rem;
    h2 {
      font-size: 1.8rem;
    }
  }
  @media (max-width: 680px) {
    grid-gap: 2rem;
    grid-template-columns: 1fr;
  }
`
const CountItem = styled(motion.div)`
  display: grid;
  grid-template-columns: 36px 1fr;
  grid-gap: 16px;
  margin-bottom: 20px;
  align-items: center;
  p {
    font-family: "Articulat";
    font-weight: 500;
    margin: 0;
    font-size: 1rem;
    color: ${Colors.dark};
  }
`
const Count = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 23px;
  background-color: ${Colors.gold};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  font-weight: 800;
  font-family: "Articulat";
`
const InvisalignImage = styled.img`
  height: 350px;
  width: auto;
  margin: 0;
  object-fit: cover;
  border-radius: 12px;
`
const Copy = styled.p`
  font-family: "Articulat";
  font-weight: 400;
  margin: 0;
  font-size: 1.2rem;
  max-width: 55ch;
  line-height: 1.4;

  @media (max-width: 800px) {
    font-size: 1.1rem;
  }
`
export default function HowItWorks() {
  return (
    <div>
      <Grid>
        <div>
          <motion.h2
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              duration: 1,
            }}
          >
            The technology behind the latest and greatest teeth straightening
            method - Invisalign
          </motion.h2>
          <motion.p
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ amount: 0.5 }}
          >
            Choosing the right Invisalign provider is critical in ensuring your
            treatment’s success and helps to deliver the smile you want.
            Choosing Smile Inn Dental means choosing a certified and trusted
            Emerald Invisalign Award winning clinic, the highest awarded tier
            from Invisalign!
          </motion.p>

          <motion.p
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              duration: 1,
            }}
            viewport={{ amount: 0.5 }}
          >
            <u>
              <b>Invisalign patients commit to their smile by:</b>
            </u>
          </motion.p>
        </div>
        <div>
          <InvisalignImage
            style={{ objectFit: "cover", height: 450, margin: 0 }}
            src={require("../../images/invisalign-shoot/invisalign-2.jpg")}
          />
        </div>
      </Grid>
      <div style={{ marginBottom: 32 }}>
        <CountItem
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{
            duration: 1,
          }}
          viewport={{ amount: 0.5 }}
        >
          <Count>1</Count>
          <p>Wear your aligners at least 22 hours a day</p>
        </CountItem>
        <CountItem
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{
            duration: 1,
          }}
          viewport={{ amount: 0.5 }}
        >
          <Count>2</Count>
          <p>Changing aligners every 7 to 14 days</p>
        </CountItem>
        <CountItem
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{
            duration: 1,
          }}
          viewport={{ amount: 0.5 }}
        >
          <Count>3</Count>
          <p>Visiting the clinic regularly to check your smile progress </p>
        </CountItem>
      </div>
      <Copy>
        Over time, the aligners will slowly reposition your teeth into their
        ideal position. Most treatments last around 12-18 months, but you’ll
        start seeing results much sooner!
      </Copy>
      <Spacer size={36} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <AppointmentButton title="BOOK A CONSULTATION" />
      </div>
    </div>
  )
}
