import React from "react"
import styled from "styled-components"
import { Colors } from "../../colors/Colors"
import { motion } from "framer-motion/dist/framer-motion"
import ReactPlayer from "react-player/lazy"
import Spacer from "../spacer"
import "./player.css"
import { AppointmentButton } from "../appointment/appointment-button"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  position: relative;

  h2 {
    font-family: "Articulat";
    color: ${Colors.gold};
    font-size: 2.2rem;
    font-weight: 800;
    margin-bottom: 16px;
    text-align: center;
    max-width: 23ch;
  }

  > p {
    font-family: "Articulat";
    font-size: 1.1rem;
    line-height: 1.4;
    font-weight: 400;
    margin-bottom: 5rem;
    text-align: center;
    max-width: 40ch;
  }
`
const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`
const Table = styled.table`
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  /* border: 1px solid #dfdfdf; */
`
const TRow = styled.tr``
const THead = styled.th`
  font-family: "Articulat";
  border-color: #e0e0e0;
`
const TDetail = styled.td`
  font-family: "Articulat";
  border-color: #e0e0e0;
`
const InvisalignImage = styled.img`
  height: 40px;
  width: auto;
  object-fit: contain;
  margin: 0;
`
const CaseContainer = styled(motion.div)`
  position: absolute;
  right: 0;
  bottom: -35%;
  width: 350px;

  @media (max-width: 980px) {
    width: 300px;
    bottom: -45%;
  }
  @media (max-width: 780px) {
    width: 220px;
    bottom: -35%;
    right: -5vw;
  }
  @media (max-width: 600px) {
    width: 180px;
  }

  @media (max-width: 380px) {
    display: none;
  }
`
const Player = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 700px;
  aspect-ratio: 16 / 9;
  /* padding-top: 56.25%; */
  /* playerratio: 100 / (1280 / 720); */
`
export default function ComparedToBraces() {
  return (
    <Container>
      <h2>Is Invisalign Right For You? You get to decide.</h2>
      <p>
        The SmartTrack material infused in the design of Invisalign trays are a
        stand out compared to traditional braces. Here’s how:
      </p>
      {/* <CaseContainer
        initial={{ opacity: 0, x: "100%" }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 2, ease: [0, 0.55, 0.45, 1] }}
      >
        <img src={require("../../images/invisalign-case.png")} />
      </CaseContainer> */}
      <TableContainer>
        <Table>
          <TRow>
            <THead></THead>
            <THead>Traditional Braces</THead>
            <THead>
              <InvisalignImage
                src={require("../../images/Emerald-Invisalign.png")}
              />
            </THead>
          </TRow>
          <TRow>
            <THead>Appearance</THead>
            <TDetail>Wired metal and brackets </TDetail>
            <TDetail>Invisible, clear aligners </TDetail>
          </TRow>
          <tr>
            <THead>Removability</THead>
            <TDetail>Cannot be removed until after treatment </TDetail>
            <TDetail>Can be removed daily </TDetail>
          </tr>
          <tr>
            <THead>Maintenance </THead>
            <TDetail>
              Every month patients must got to tighten brace brackets{" "}
            </TDetail>
            <TDetail>
              Change trays every two weeks with intermittent visits in between
              treatment
            </TDetail>
          </tr>
          <tr>
            <THead>Time </THead>
            <TDetail>Generally 2 years </TDetail>
            <TDetail>As short as 6-18 months </TDetail>
          </tr>
        </Table>
      </TableContainer>
      <Spacer size={90} />
      <Player>
        <ReactPlayer
          playsinline
          controls
          //   light
          url={require("../../images/popcorn-invis.mp4")}
          className="react-player"
          width="100%"
          height="100%"
        />
      </Player>
      <p
        style={{
          textAlign: "center",
          marginTop: 10,
          fontSize: 14,
          fontWeight: 500,
          fontStyle: "italic",
        }}
      >
        Video demonstrating the freedom to live with Invisalign
      </p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <AppointmentButton title="BOOK A CONSULTATION" />
      </div>
    </Container>
  )
}
