import React, { useState } from "react"
import styled from "styled-components"
import { motion } from "framer-motion/dist/framer-motion"

const variants = {
  closed: { y: -10, height: 0 },
  showing: { y: 0, height: "100%" },
}

const Container = styled.div`
  margin-bottom: 20px;
  width: 100%;
  border-bottom: 1px solid #fafafa;
  text-align: left;
`
const Question = styled.h4`
  font-size: 20px;
  color: #333;
  cursor: pointer;
  transition: 0.2s ease;
  margin-bottom: 10px;
  font-weight: 600;
  line-height: 120%;
  font-family: "Articulat";
  :hover {
    color: black;
  }
  @media (max-width: 550px) {
    font-size: 20px;
  }
  span {
    color: grey;
    font-size: 1.3rem;
    font-weight: 700;
  }
`
const Answer = styled(motion.div)`
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 15px;
  p {
    font-family: "Articulat";
    line-height: 1.45;
    font-size: 0.9rem;
  }
`

export default function FaqItem({ faq }) {
  const { question, answer } = faq
  const [showing, setShowing] = useState(false)
  const clearFormatting = string => {
    return string.replace("&#8217;", "'")
  }
  return (
    <Container
      onClick={() => {
        setShowing(!showing)
      }}
    >
      <Question onClick={() => setShowing(!showing)}>
        <span>{showing ? "-" : "+"}</span> {clearFormatting(question)}
      </Question>
      <Answer
        onClick={() => setShowing(!showing)}
        animate={showing ? "showing" : "closed"}
        variants={variants}
      >
        {showing ? <p>{answer}</p> : []}
      </Answer>
      {/* <Answer
				onClick={() => setShowing(!showing)}
				animate={showing ? "showing" : "closed"}
				variants={variants}
				dangerouslySetInnerHTML={{ __html: answer }}
			/> */}
    </Container>
  )
}
