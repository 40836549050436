import React from "react"
import styled from "styled-components"
import { Colors } from "../../colors/Colors"
import { AppointmentButton } from "../appointment/appointment-button"

const InvisalignImage = styled.img`
  height: 65vh;
  width: 100%;
  margin: 0;
  object-fit: cover;
  border-radius: 3px;
`
const HeaderGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5rem;
  margin-bottom: 8rem;
  width: 100%;
  align-items: center;

  h1 {
    font-family: "Articulat";
    color: ${Colors.gold};
    font-size: 3rem;
    font-weight: 800;
    margin-bottom: 16px;
  }
  h2 {
    font-family: "Articulat";
    color: ${Colors.dark};
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: 16px;
  }

  p {
    font-family: "Articulat";
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    max-width: 35ch;
    margin-bottom: 32px;
  }

  @media (max-width: 800px) {
    grid-gap: 3rem;
    h1 {
      font-size: 2.3rem;
    }
    p {
      font-size: 1.1rem;
    }
  }

  @media (max-width: 700px) {
    grid-gap: 3rem;
    grid-template-columns: 1fr;
  }
`

export default function InvisalignHeader({
  title = "Your Smile Journey Starts Here.",
  body = "Insecure about your smile? Smile Inn Dental is the top Invisalign clinic in the Caribbean, using teeth straightening technology to invisibly correct overcrowded, misaligned and crooked teeth.",
}) {
  console.log({ body })
  return (
    <HeaderGrid>
      <div>
        <h1>{title}</h1>
        <p>{body}</p>
        <AppointmentButton title="Start Your Smile Journey" />
      </div>
      <div>
        <InvisalignImage
          src={require("../../images/invisalign-shoot/invisalign-1.jpg")}
        />
      </div>
    </HeaderGrid>
  )
}
