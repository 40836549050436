import React from "react"
import styled from "styled-components"
import { Colors } from "../../colors/Colors"
import { AppointmentButton } from "../appointment/appointment-button"

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  grid-gap: 5rem;
  width: 100%;
  margin-bottom: 36px;
  align-items: flex-start;

  h2 {
    font-family: "Articulat";
    color: ${Colors.gold};
    font-size: 2.2rem;
    font-weight: 800;
    margin-bottom: 16px;
  }

  p {
    font-family: "Articulat";
    font-size: 1.1rem;
    line-height: 1.4;
    max-width: 40ch;
    margin-bottom: 3rem;
  }

  @media (max-width: 800px) {
    grid-gap: 2rem;
    h2 {
      font-size: 1.8rem;
    }
    p {
      font-size: 0.98rem;
    }
  }
  @media (max-width: 680px) {
    grid-gap: 2rem;
    grid-template-columns: 1fr;
  }
`
const CountItem = styled.div`
  display: grid;
  grid-template-columns: 36px 1fr;
  grid-gap: 16px;
  margin-bottom: 20px;
  align-items: center;
  p {
    font-family: "Articulat";
    font-weight: 500;
    margin: 0;
    font-size: 1.05rem;
    color: ${Colors.dark};
  }
`
const Count = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 23px;
  background-color: ${Colors.gold};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  font-weight: 800;
  font-family: "Articulat";
`
const InvisalignImage = styled.img`
  height: 60vh;
  width: 100%;
  margin: 0;
  object-fit: cover;
  border-radius: 12px;
`
const Copy = styled.p`
  font-family: "Articulat";
  font-weight: 400;
  margin: 0;
  font-size: 1.2rem;
  max-width: 50ch;
  line-height: 1.4;
`
export default function WhyInvisalign() {
  return (
    <div>
      <Grid>
        <div>
          <h2>An Invisalign Journey That Leaves You SmileInn</h2>
          <p>
            Choosing the right Invisalign provider is critical in ensuring your
            treatment’s success and helps to deliver the smile you want.
            Choosing Smile Inn Dental means choosing a certified and trusted
            Emerald Invisalign Award winning clinic, the highest awarded tier
            from Invisalign!
          </p>
          <div style={{ marginBottom: 32 }}>
            <CountItem>
              <Count />
              <p>
                Fast — You’ll start seeing results in as little as a few weeks
              </p>
            </CountItem>
            <CountItem>
              <Count />
              <p>
                Clear — Clear aligners that are discrete and virtually invisible
              </p>
            </CountItem>
            <CountItem>
              <Count />
              <p>Comfortable — No sharp, bulky metal braces needed!</p>
            </CountItem>
            <CountItem>
              <Count />
              <p>Comparable and often times cheaper than traditional braces</p>
            </CountItem>
          </div>
        </div>
        <div>
          <InvisalignImage
            src={require("../../images/invisalign-shoot/invisalign-3.jpg")}
          />
        </div>
      </Grid>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <AppointmentButton />
      </div>
    </div>
  )
}
