import React from "react"
import styled from "styled-components"
import { Colors } from "../../colors/Colors"
import { AppointmentButton } from "../appointment/appointment-button"
import Spacer from "../spacer"
import FaqItem from "./faq-item"

const Container = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Title = styled.h2`
  margin: 0;
  text-align: center;
  font-weight: 500;
  font-family: "Articulat";
  color: ${Colors.gold};
  font-size: 2.2rem;
  font-weight: 800;
`
const ItemsContainer = styled.div`
  background: white;
  padding-top: 30px;
`
export const MailCTA = styled.a`
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  color: #311f4d;
  width: 100%;
  display: inline-block;
  margin-bottom: 30px;
  line-height: 1.4;
`

const FaqSectionContainer = ({ title }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Spacer size={32} />
      <ItemsContainer>
        {faqs?.map((faq, index) => (
          <FaqItem key={index} faq={faq} />
        ))}
      </ItemsContainer>
      <AppointmentButton title="Book a consultation" />
    </Container>
  )
}

export default FaqSectionContainer

const faqs = [
  {
    question: "What is Invisalign?",
    answer:
      "Invisalign is the virtually invisible way to straighten your teeth and achieve the smile you’ve always dreamed of. Using advanced 3D computer-imaging technology, Invisalign depicts your complete treatment plan based on your Invisalign Provider’s prescription, from the initial position of your teeth to the final desired position. Then a series of clear aligners are custom-made for your teeth – and your teeth only – to move them little by little. Each aligner is worn for 1-2 weeks before being replaced by the next in the series, gradually moving towards the projected final position. Your Invisalign treatment time will be determined by your Invisalign Provider, based on your specific needs.",
  },
  {
    question: "How much does Invisalign cost?",
    answer:
      "As with other types of orthodontic treatment, the cost of Invisalign is dependent on the complexity of your issue and your course of treatment. As a general rule the cost is comparable to traditional fixed braces.",
  },
  {
    question: "Is the Invisalign treatment painful?",
    answer:
      "Some people may experience temporary, minor discomfort for a few days, at the beginning of each new stage of treatment when they insert a new aligner. This is normal and is typically described as a feeling of pressure. It is a sign that the aligners are working – moving your teeth gradually, helping you to achieve the final results. This discomfort typically goes away in a couple of days.",
  },
  {
    question: "How long does the Invisalign treatment take?",
    answer:
      "The total duration of treatment depends on the complexity of the treatment and is generally comparable to that of traditional braces. Typically treatment can last between 3 and 18 months. During your consultation, your Invisalign Provider can provide you with more detailed information.",
  },
]
