import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion/dist/framer-motion"

const Highlight = styled.span`
  text-decoration: underline;
  text-decoration-thickness: 3px;
  color: #b10f15;
  font-weight: 700;
`
const Grid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 900px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
`
const Text = styled(motion.h3)`
  font-family: "Articulat";
  font-size: 1.35rem;
  max-width: 30ch;
  line-height: 1.4;
  font-weight: 600 !important;
  text-align: center;

  @media (max-width: 750px) {
    font-size: 1.1rem;
  }

  @media (max-width: 600px) {
    font-size: 1.3rem;
    text-align: center;
  }
`

const AwardsGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
  margin: 0 auto;
  max-width: 550px;

  @media (max-width: 750px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
  }
`
const Item = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const InvisalignImage = styled.img`
  height: 90px;
  width: auto;
  margin: 0;
  object-fit: contain;

  @media (max-width: 750px) {
    height: 70px;
  }
`

export default function InvisalignAwards({ noTitle }) {
  return (
    <Grid>
      {!noTitle && (
        <Text
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ amount: 0.5 }}
          transition={{ duration: 1.5, ease: [0, 0.55, 0.45, 1] }}
        >
          Smile Inn Dental is <Highlight>Emerald Certified</Highlight>, the
          highest level in the Caribbean.
        </Text>
      )}
      <AwardsGrid>
        <Item
          initial={{ opacity: 0, x: 10 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.5, ease: [0, 0.55, 0.45, 1], delay: 0.1 }}
          viewport={{
            amount: 0.5,
          }}
        >
          <InvisalignImage src={require("../images/awards/Gold.png")} />
        </Item>
        <Item
          initial={{ opacity: 0, x: 10 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.5, ease: [0, 0.55, 0.45, 1], delay: 0.2 }}
          viewport={{
            amount: 0.5,
          }}
        >
          <InvisalignImage src={require("../images/awards/PlatELite.png")} />
        </Item>
        <Item
          initial={{ opacity: 0, x: 10 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.5, ease: [0, 0.55, 0.45, 1], delay: 0.3 }}
          viewport={{
            amount: 0.5,
          }}
        >
          <InvisalignImage src={require("../images/awards/Diamond.png")} />
        </Item>
        <Item
          initial={{ opacity: 0, x: 10 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.5, ease: [0, 0.55, 0.45, 1], delay: 0.4 }}
          viewport={{
            amount: 0.5,
          }}
        >
          <InvisalignImage src={require("../images/awards/DiamondElite.png")} />
        </Item>
        <Item
          initial={{ opacity: 0, x: 10 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.5, ease: [0, 0.55, 0.45, 1], delay: 0.5 }}
          viewport={{
            amount: 0.5,
          }}
        >
          <InvisalignImage src={require("../images/awards/Emerald.png")} />
        </Item>
      </AwardsGrid>
    </Grid>
  )
}
