import React, { useState } from "react"
import styled from "styled-components"
import { Colors } from "../../colors/Colors"
import { AppointmentButton } from "../appointment/appointment-button"
import InvisalignAwards from "../invisalign-awards"
import Spacer from "../spacer"
import { useBoolean, useInterval } from "react-use"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-family: "Articulat";
    color: ${Colors.gold};
    font-size: 2.2rem;
    font-weight: 800;
    margin-bottom: 16px;
    text-align: center;
    max-width: 20ch;
  }
`
const Body = styled.p`
  font-family: "Articulat";
  font-size: 1.4rem;
  line-height: 1.4;
  font-weight: 500;
  margin-bottom: 2rem;
  text-align: center;
  max-width: 40ch;
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  row-gap: 2rem;
  width: 100%;
  margin-bottom: 2rem;
  align-items: center;
  padding: 10px;
  border: 1px solid #dfdfdf;
  --shadow-color: 0deg 0% 75%;
  --shadow-elevation-low: 0px 0.5px 0.5px hsl(var(--shadow-color) / 0.28),
    0.1px 0.8px 0.8px -1.6px hsl(var(--shadow-color) / 0.24),
    0.1px 1.8px 1.9px -3.1px hsl(var(--shadow-color) / 0.2);
  --shadow-elevation-medium: 0px 0.5px 0.5px hsl(var(--shadow-color) / 0.23),
    0.1px 1.1px 1.1px -0.8px hsl(var(--shadow-color) / 0.21),
    0.2px 2.2px 2.3px -1.6px hsl(var(--shadow-color) / 0.19),
    0.4px 4.6px 4.7px -2.4px hsl(var(--shadow-color) / 0.18),
    0.7px 9.1px 9.4px -3.1px hsl(var(--shadow-color) / 0.16);
  --shadow-elevation-high: 0px 0.5px 0.5px hsl(var(--shadow-color) / 0.22),
    0.1px 1.6px 1.6px -0.3px hsl(var(--shadow-color) / 0.21),
    0.2px 2.6px 2.7px -0.7px hsl(var(--shadow-color) / 0.2),
    0.3px 4px 4.1px -1px hsl(var(--shadow-color) / 0.19),
    0.5px 6px 6.2px -1.4px hsl(var(--shadow-color) / 0.18),
    0.7px 9px 9.3px -1.7px hsl(var(--shadow-color) / 0.17),
    1.1px 13.1px 13.5px -2.1px hsl(var(--shadow-color) / 0.16),
    1.5px 18.8px 19.4px -2.4px hsl(var(--shadow-color) / 0.15),
    2.1px 26.2px 27px -2.8px hsl(var(--shadow-color) / 0.14),
    2.9px 35.7px 36.8px -3.1px hsl(var(--shadow-color) / 0.14);

  box-shadow: var(--shadow-elevation-medium);
`
const Image = styled.img`
  margin: 0;
  width: 100%;
  aspect-ratio: 14/7;
  object-fit: cover;
  transition: 0.3s ease;
`
const Caption = styled.p`
  text-align: left;
  font-family: "Articulat";
  font-size: 0.8rem;
  opacity: 0.8;
  width: 100%;
`
const Case = styled.div`
  margin-bottom: 2rem;
`
export default function BeforeAfterSection({ gallery }) {
  return (
    <Container>
      <InvisalignAwards noTitle />
      <Spacer size={30} />
      {/* <h2>Some of our favourite Smile Transformations</h2> */}
      <Body>
        See how some of our Smile Inn Dental patients have already transformed
        their smile.
      </Body>
      <AppointmentButton title="Transform your smile now" />
      <Spacer size={60} />
      <Case>
        {gallery.map((item, index) => (
          <Grid>
            <React.Fragment key={index}>
              <Image src={item.before_image.source_url} />
              <Image src={item.after_image.source_url} />
            </React.Fragment>
          </Grid>
        ))}
      </Case>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <AppointmentButton title="Book a consultation now" />
      </div>
    </Container>
  )
}

const BeforeAfterItem = ({ before, after, caption }) => {
  const [current, setCurrent] = useState("before")
  const [delay, setDelay] = useState(2000)
  const [isRunning, toggleIsRunning] = useBoolean(true)

  useInterval(
    () => {
      if (current === "before") {
        setCurrent("after")
      } else {
        setCurrent("before")
      }
    },
    isRunning ? delay : null
  )

  return (
    <Case>
      <Image
        onMouseEnter={() => toggleIsRunning()}
        onMouseLeave={() => toggleIsRunning()}
        src={current === "before" ? before : after}
      />
      <div>
        <Caption>{caption}</Caption>
      </div>
    </Case>
  )
}
