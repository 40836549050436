import React, { Component } from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { Colors } from "../../colors/Colors"
import { graphql, Link } from "gatsby"
import { AppointmentButton } from "../../components/appointment/appointment-button"
import InvisalignAwards from "../../components/invisalign-awards"
import HowItWorks from "../../components/invisalign/how-it-works"
import WhatToExpect from "../../components/invisalign/what-to-expect"
import WhyInvisalign from "../../components/invisalign/why-invisalign"
import FaqSectionContainer from "../../components/invisalign/faq-section"
import BeforeAfterSection from "../../components/invisalign/before-after"
import Spacer from "../../components/spacer"
import InvisalignHeader from "../../components/invisalign/header"
import ComparedToBraces from "../../components/invisalign/compared-to-braces"
import Testimonials from "./testimonials"
import ReactPlayer from "react-player/lazy"
import withLocation from "../../components/withLocation"

const MainContainer = styled.div`
  display: flex;
  padding: 22vh 20px;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-start;
  max-width: 1080px;
  margin: 0 auto;

  @media (max-width: 700px) {
    padding: 7vh 20px;
  }
`
const FullWidth = styled.div`
  // break out the container and full the width of the screen
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  padding: 10vh 0;
  background: #c5a46228;

  @media (max-width: 600px) {
    padding: 10vh 0 20vh 0;
  }

  @media (max-width: 380px) {
    padding: 10vh 0;
  }
`

const Player = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 700px;
  aspect-ratio: 16 / 9;
  /* padding-top: 56.25%; */
  /* playerratio: 100 / (1280 / 720); */
`
const IteroImage = styled.img`
  width: 120px;
  height: auto;
  object-fit: contain;
  text-align: center;
`
class InvisalignPage extends Component {
  constructor(props) {
    super(props)
    this.faqRef = React.createRef()

    this.state = {}
  }

  handleLearnMore = () => {
    this.faqRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
  }

  render() {
    const invisalignPageData = this.props.data.invisalign.acf
    const { search } = this.props
    const title = search?.title ?? undefined
    const body = search?.body ?? undefined
    const showComparison = search?.showComparison ?? undefined
    const process = search?.process ?? undefined
    return (
      <Layout noHeader>
        <SEO
          title="Start Your Invisalign Journey with Smile Inn Dental"
          description="Smile Inn is Emerald Certified in Invisalign Technology and is the first to feature the iTero 5D Scanner - allowing you to see your future smile in minutes!"
        />
        <MainContainer>
          {process && (
            <>
              <WhatToExpect />
              <Spacer size={120} />
            </>
          )}
          {showComparison && (
            <>
              <ComparedToBraces />
              <Spacer size={50} />
            </>
          )}
          <InvisalignHeader title={title} body={body} />
          {/* <div style={{ marginBottom: "8rem" }}> */}
          <InvisalignAwards />
          <Spacer size={60} />
          <Player>
            <ReactPlayer
              playsinline
              controls
              //   light
              url={require("../../videos/itero-scan.mp4")}
              className="react-player"
              width="100%"
              height="100%"
            />
            {/* <AppointmentButton title="Book a free consultation" /> */}
          </Player>

          <p
            style={{
              textAlign: "center",
              marginTop: 10,
              fontSize: 14,
              fontWeight: 500,
              fontStyle: "italic",
            }}
          >
            See the iTero 5D Scanner in action
          </p>
          <p style={{ textAlign: "center", marginTop: 10 }}>
            <IteroImage src={require("../../images/itero.png")} />
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <AppointmentButton title="BOOK A CONSULTATION" />
          </div>
          <Spacer size={60} />

          {/* </div> */}
          {!showComparison && (
            <>
              <FullWidth>
                <ComparedToBraces />
              </FullWidth>
            </>
          )}
          <Spacer size={120} />
          {/* How it works */}
          <HowItWorks />
          <Spacer size={120} />
          {/* What to expect */}
          {!process && <WhatToExpect />}
          <Spacer size={120} />
          <WhyInvisalign />
          <Spacer size={120} />
          <BeforeAfterSection gallery={invisalignPageData.before_and_after} />
          <Spacer size={120} />
          <Testimonials testimonials={this.props.data.testimonials.edges} />
          <Spacer size={120} />
          <FaqSectionContainer title="Frequently Asked Questions" />
        </MainContainer>
      </Layout>
    )
  }
}

export default withLocation(InvisalignPage)
const faqs = [
  {
    title: "What is Invisalign?",
    copy: [
      "Invisalign is the virtually invisible way to straighten your teeth and achieve the smile you’ve always dreamed of. Using advanced 3D computer-imaging technology, Invisalign depicts your complete treatment plan based on your Invisalign Provider’s prescription, from the initial position of your teeth to the final desired position. Then a series of clear aligners are custom-made for your teeth – and your teeth only – to move them little by little. Each aligner is worn for 1-2 weeks before being replaced by the next in the series, gradually moving towards the projected final position. Your Invisalign treatment time will be determined by your Invisalign Provider, based on your specific needs.",
    ],
  },
  {
    title: "What are the benefits of Invisalign?",
    copy: [
      "Invisalign aligners are nearly invisible. No one may even notice that you’re wearing these virtually invisible aligners, making Invisalign a seamless fit with your lifestyle and day-to-day interactions with others.",
      "Invisalign aligners are removable giving you the flexibility to eat and drink what you want during treatment by simply removing the aligners. And you can also remove the aligners to brush and floss as you normally would for fresh breath and to maintain good oral hygiene.",
      "Unlike traditional fixed braces, there are no metal brackets or wires. This means that in most cases less time is spent in your Invisalign Provider’s practice for adjustments.",
      "And finally, Invisalign allows you to view your virtual results and treatment plan before you even begin treatment so you can see how your teeth are expected to look when your treatment is complete.",
    ],
  },
  {
    title: "How much does Invisalign cost?",
    copy: [
      "As with other types of orthodontic treatment, the cost of Invisalign is dependent on the complexity of your issue and your course of treatment. As a general rule the cost is comparable to traditional fixed braces.",
    ],
  },

  {
    title: "How does Invisalign work?",
    copy: [
      "Invisalign aligners move teeth with an appropriate placement of controlled movement on the teeth. Unlike traditional braces, Invisalign not only controls the amount of movement per aligner, but also the timing of the movement. So at each stage, only certain teeth are allowed to move, as per the Invisalign treatment plan for that particular stage. This results in an efficient force delivery system that moves teeth into the desired prescribed position.",
    ],
  },
  {
    title: "Is the Invisalign treatment painful?",
    copy: [
      "Some people may experience temporary, minor discomfort for a few days, at the beginning of each new stage of treatment when they insert a new aligner. This is normal and is typically described as a feeling of pressure. It is a sign that the aligners are working – moving your teeth gradually, helping you to achieve the final results. This discomfort typically goes away in a couple of days.",
    ],
  },
  {
    title: "How long does the Invisalign treatment take?",
    copy: [
      "The total duration of treatment depends on the complexity of the treatment and is generally comparable to that of traditional braces. Typically treatment can last between 3 and 18 months. During your consultation, your Invisalign Provider can provide you with more detailed information.",
    ],
  },
]

export const generalQuery = graphql`
  query(
    $title: String = "Invisalign Teeth Straightening"
    $category: String = "Google Review"
    $id: String = "a78f1e74-6a64-5aa9-a60b-288d6c851703"
  ) {
    allWordpressPage(filter: { title: { eq: $title } }) {
      edges {
        node {
          featured_media {
            source_url
            localFile {
              childImageSharp {
                fluid(maxHeight: 1500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    testimonials: allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: $category } } } }
    ) {
      edges {
        node {
          title
          content
        }
      }
    }
    invisalign: wordpressPage(id: { eq: $id }) {
      acf {
        before_and_after {
          after_image {
            source_url
          }
          before_image {
            source_url
          }
        }
      }
    }
  }
`
