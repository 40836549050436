import React from "react"
import styled from "styled-components"
import { Colors } from "../../colors/Colors"
import { AppointmentButton } from "../appointment/appointment-button"
import { motion } from "framer-motion/dist/framer-motion"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-family: "Articulat";
    color: ${Colors.gold};
    font-size: 2.2rem;
    font-weight: 800;
    margin-bottom: 16px;
    text-align: center;
    max-width: 27ch;
  }

  > p {
    font-family: "Articulat";
    font-size: 1.1rem;
    line-height: 1.4;
    font-weight: 400;
    margin-bottom: 5rem;
    text-align: center;
    max-width: 45ch;
  }
`
const Grid = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
  width: 100%;
  margin-bottom: 5rem;
  align-items: center;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

const CountItem = styled.div`
  display: grid;
  grid-template-columns: 36px 1fr;
  grid-gap: 16px;
  margin-bottom: 20px;
  align-items: center;
  h3 {
    font-family: "Articulat";
    font-weight: 800;
    margin: 0;
    font-size: 1.7rem;
    color: ${Colors.dark};
  }
`
const Count = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 23px;
  background-color: ${Colors.gold};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  font-weight: 800;
  font-family: "Articulat";
`
const ItemCopy = styled.p`
  font-family: "Articulat";
  font-size: 1rem;
  font-weight: 500;
  opacity: 0.8;
  margin-bottom: 36px;
  text-align: left;
  max-width: 40ch;
`
const InvisalignImage = styled.img`
  height: 500px;
  width: 100%;
  margin: 0;
  object-fit: cover;
  border-radius: 12px;
`

export default function WhatToExpect() {
  return (
    <Container>
      <h2>The Smile Inn Invisalign Experience is just that- an experience! </h2>
      <p>
        Once in the examination room, you’ll be invited to sit back, relax and
        open wide. One of our trained nurses will then digitally scan your mouth
        using the preferred scanner worldwide- the iTero. In a matter of
        seconds, accurate 3D images of the inside of your mouth appears on the
        iTero prompter. At this point, your dentist takes you through areas of
        concern, treatment course and the work begins on customizing your
        Invisalign trays.
      </p>
      <Grid
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{
          duration: 1,
        }}
        viewport={{ amount: 0.5, once: true }}
      >
        <div>
          {/* <InvisalignImage src={require("../../images/invisalign.jpg")} /> */}
          <InvisalignImage src="https://images.unsplash.com/photo-1615233201105-7b04d2a9a926?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=385&q=90" />
        </div>
        <div>
          <CountItem>
            <Count>1</Count>
            <h3>Explore Your Smile Status, without Commitment </h3>
          </CountItem>
          <ItemCopy>
            <b>
              <u>Schedule your Invisalign® consultation</u>
            </b>{" "}
            and if you’re a candidate for Invisalign clear aligners one of our
            Invisalign certified Dentists will develop a treatment plan to
            correct your smile. You will receive new, custom-molded clear
            aligners every 7 to 14 days to subtly enhance your smile without the
            burden of traditional braces.
          </ItemCopy>
          <AppointmentButton title="Schedule Your Consult" />
        </div>
      </Grid>
      <Grid
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{
          duration: 1,
        }}
        viewport={{ amount: 0.5, once: true }}
      >
        <div>
          <CountItem>
            <Count>2</Count>
            <h3>See Your Future Smile, Now </h3>
          </CountItem>
          <ItemCopy>
            During your smile assessment you’ll experience the first iTero 5D
            scanner with integrated dental imaging technology, that uses
            powerful visualization, showing you in real time, your smile from
            beginning to end.
          </ItemCopy>
          <AppointmentButton title="Book a consultation" />
        </div>
        <div>
          <InvisalignImage src={require("../../images/itero-scan.png")} />
        </div>
      </Grid>
      <Grid
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{
          duration: 1,
        }}
        viewport={{ amount: 0.5, once: true }}
      >
        <div>
          <InvisalignImage
            src={require("../../images/invisalign-shoot/invisalign-4.jpg")}
          />
        </div>
        <div>
          <CountItem>
            <Count>3</Count>
            <h3>You’re On Your Way to SmileInn!✨</h3>
          </CountItem>
          <ItemCopy>
            If you’re a candidate for Invisalign clear aligners, a custom
            treatment plan will be developed to correct your smile. You will
            receive new, custom-molded clear aligners every few weeks,
            progressing your smile.
          </ItemCopy>
          <AppointmentButton title="Start your smile journey now" />
        </div>
      </Grid>
    </Container>
  )
}
