import React from "react"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import styled from "styled-components"
import ReactAliceCarousel from "react-alice-carousel"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import TestimonialCard from "../../components/testimonial-card"
import { GoPrimitiveDot } from "react-icons/go"
import { Colors } from "../../colors/Colors"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
}

const stagePadding = {
  paddingLeft: 0, // in pixels
  paddingRight: 0,
}

const responsive = {
  0: {
    items: 1,
  },
  1024: {
    items: 1,
  },
}

const ReviewsContainer = styled.div`
  position: relative;
  width: 90%;
  margin: 0 auto;
`
const ArrowLeft = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 20px;
  cursor: pointer;
  z-index: 10;

  @media (max-width: 768px) {
    left: -20px;
  }
`
const ArrowRight = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 20px;
  cursor: pointer;
  z-index: 10;

  @media (max-width: 768px) {
    right: -20px;
  }
`
const NavContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`
const Nav = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
`
const Item = styled.li`
  margin-right: 10px;
  transition: 0.3s ease;
  cursor: pointer;
  color: ${props => (props.active ? Colors.gold : "black")};
`

export default function Testimonials({ testimonials }) {
  console.log({ testimonials })
  const [currentIndex, setIndex] = React.useState(0)
  const [itemsInSlide, setItemsInSlide] = React.useState()

  const slideTo = num => {
    setIndex(num)
  }

  const handleOnSlideChange = event => {
    const { itemsInSlide, item } = event
    setIndex(item)
    setItemsInSlide(itemsInSlide)
  }

  const createDots = () => {
    let Dots = []
    for (let i = 0; i < testimonials?.length; i++) {
      Dots.push(
        <Item key={i} onClick={() => slideTo(i)}>
          <GoPrimitiveDot
            color={currentIndex === i ? Colors.gold : "#E9E9E9"}
            size={16}
          />
        </Item>
      )
    }
    return Dots
  }

  return (
    <div>
      <ReviewsContainer>
        <ArrowLeft
          onClick={() => slideTo(currentIndex === 0 ? 0 : currentIndex - 1)}
        >
          <FaChevronLeft />
        </ArrowLeft>
        <ArrowRight
          onClick={() =>
            slideTo(
              currentIndex === testimonials?.length - 1
                ? testimonials?.length - 1
                : currentIndex + 1
            )
          }
        >
          <FaChevronRight />
        </ArrowRight>
        <ReactAliceCarousel
          stagePadding={stagePadding}
          buttonsDisabled
          dotsDisabled
          autoPlayInterval={8000}
          autoPlayDirection="ltr"
          stopAutoPlayOnHover
          autoPlay={true}
          onSlideChanged={handleOnSlideChange}
          slideToIndex={currentIndex}
          responsive={responsive}
        >
          {testimonials?.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              author={testimonial.node.title}
              review={testimonial.node.content}
            />
          ))}
        </ReactAliceCarousel>
      </ReviewsContainer>
      <NavContainer>
        <Nav>{createDots()}</Nav>
      </NavContainer>
    </div>
  )
}
